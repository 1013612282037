import React, { useState } from "react"
import { Button, Modal } from "antd"
import ImageGallery from "../components/ImageGallery"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

const Colliers_pendentifs = () => {

//Hooks
  const [dataContact, setDataContact] = useState(false)

//Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
  <Layout>
    <SEO title="Colliers & Pendentifs" />

    <div className="slideshow">
      <div className="usp">
        <div  className="usp-txt-header">Colliers & Pendentifs</div>
        {/*<div  className="usp-txt">Du diamant à la pierre de couleur,<br/> une multitude de combinaison s'offre à vous.</div>*/}
      </div>
    </div>
    <div className="border-gold-bottom" />

    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Colliers & Pendentifs</h2>



    <h3 className="h3-mobile" style={{textAlign: 'center'}}>Du diamant à la pierre de couleur,<br/> une multitude de combinaison s'offre à vous.</h3>

    <p className="p-2 p-font mobile-display" style={{textAlign: 'center'}}>Du diamant à la pierre de couleur, une multitude de combinaison s'offre à vous.</p>

    <section>
      <ImageGallery id="collierspendentifs" />

      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Colliers & Pendentifs') }>Nous contacter</Button></div>
      <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
        visible={ showModalContact }
        onOk={ handleContactFormOk }
        onCancel={ handleContactFormCancel }
        okButtonProps={{ style: { display: 'none' } }}
      >

        <ContactForm page={dataContact} />

      </Modal>

    </section>

  </Layout>
    </>
  )
}

export default Colliers_pendentifs
