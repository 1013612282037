import React, { useEffect, useState } from "react"
import Prismic from "prismic-javascript"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const prismicURL = "https://bijouterie-lunas.cdn.prismic.io/api/v2"

const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

export default ({ id }) => {
  const [gallery, setGallery] = useState([])

  useEffect(() => {
    const items = []

    Prismic.getApi(prismicURL).then((api) => {
      return api.getByUID("gallery", id)
    })
      .then(response => {
        if (response) {
          let empty = false;

          if (response.data.images.length === 1 && typeof response.data.images[0].image.url === 'undefined')
            empty = true;

          if (!empty) {
            response.data.images.forEach(i => {
              const item = {
                img: i.image.url,
                tmb: i.image.tmb.url,
              }

              items.push(item);
            });

            setGallery(items);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
  }, [ id ])


  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <div id="gallery-with-links" className="container content">
          <div className="col-image-with-link img-gallery-wrapper">
            {gallery.map((item, i) => <a key={i} href={item.img} data-attribute="SRL"> <img className="img" src={item.tmb} alt={id}/></a>)}
          </div>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}